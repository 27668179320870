<template>
	<div class="player" :style="cssVars">
		<audio 
			v-if="file" 
			:id="'player-' + uniqueId" 
			ref="player" 
			class="player" 
			controls
			@pause="onPause"
		>
			<source :src="audioSource" :type="audioType">
		</audio>
		<div v-if="file" class="player-controls">
			<div class="player-buttons">
				<button v-show="showControls" class="player-button backward" @mousedown="backwardAudio()">
					<i v-show="museum.code != 'MPICASSOM'" class="play mm-icon mm-icon-rewind white big" />
					<i v-show="museum.code == 'MPICASSOM'" class="fa-solid fa-backward-step" style="color: #E85F31;"  />
				</button>
				<div class="active-border">
					<!-- <button class="player-button play" :class="{background: museum.code == 'MPICASSOM'}" @click.prevent="playing = !playing"> -->
					<button class="player-button play" :class="{background: museum.code == 'MPICASSOM'}" @click="handlePlayClick">
						<i v-show="playing && museum.code != 'MPICASSOM'" class="pause mm-icon mm-icon-pause white big" />
						<i v-show="!playing && museum.code != 'MPICASSOM'" class="play mm-icon mm-icon-play white big" />
						<PlayButton v-show="!playing && museum.code == 'MPICASSOM'" />
						<i v-show="playing && museum.code == 'MPICASSOM'" class="fa-solid fa-pause" style="color: #FFFFFF;" />
					</button>
				</div>
				<button v-show="showControls" class="player-button forward" @mousedown="forwardAudio()">
					<i v-show="museum.code != 'MPICASSOM'" class="play mm-icon mm-icon-forward white big" />
					<i v-show="museum.code == 'MPICASSOM'" class="fa-solid fa-forward-step" style="color:#E85F31;" />
				</button>
			</div>
			<p v-if="showTimer" v-show="showControls" class="player-timer">
				-{{ (durationSeconds + 1 - currentSeconds) | convertTimeHHMMSS }}
			</p>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import {uniqueId} from "lodash";
import  PlayButton from "./PlayButton.vue";
import axios from "axios";
const {debug} = require("@/misc/debug");

export default {
	name: "AudioPlayer",
	
	filters: {
		convertTimeHHMMSS(val) {
			let time = new Date(val * 1000).toISOString().substr(11, 8);
			return time.indexOf("00:") === 0 ? time.substr(3) : time;
		}
	},
	components: {
		PlayButton
	},
	
	props: {
		file: {
			type: [String, Object, Blob],
			default: null,
			require: true
		},
		autoPlay: {
			type: Boolean,
			default: true,
		},
		hideControlsUntilPlay: {
			type: Boolean,
			default: false,
		},
		showTimer: {
			type: Boolean,
			default: true,
		},
		textColor: {
			type: String,
			default: '#FFFFFF',
		},
		iconsColor: {
			type: String,
			default: '#FFFFFF',
		},
		additionalInformation: {
			type: Array,
			default: null
		}
	},
	
	data: () => ({
    uniqueId: uniqueId(),
		audio: undefined,
		playing: false,
		currentSeconds: 0,
		durationSeconds: 0,
		loaded: false,
		showControls: false,
		showAdditionalInformation: false,
		resources: [],
    	isBlob: false,
		volumeNodeL: undefined,
		volumeNodeR: undefined,
		maxminBalance: 50,
    audioType: 'audio/mpeg',
    audioUrl: ''
	}),
	
	computed: {
		...mapState({
			isNetworkOnline: state => state.app.isNetworkOnline,
			museum: state => state.museum.museum,
			museumId: state => state.museum.museum.idMuseum,
		}),
    // Determinar la fuente del audio
    audioSource() {
      if (this.file instanceof Blob) {
        return URL.createObjectURL(this.file);
      } else if (typeof this.file === "string") {
        return this.file;
      } else {
        return "";
      }
    },

	// audioType() {
    //   return this.isBlob ? "audio/mpeg" : "";
    // },

    // getAudioSource() {
    //   if (this.audioUrl){
    //     return this.audioUrl;
    //   }

    //   return this.audioSource;
    // },

		cssVars() {
			// See details about the play button percentage coloring here https://stackoverflow.com/a/21198231
			let percentage = (this.currentSeconds * 100) / this.durationSeconds;
			if((this.currentSeconds-1) >= this.durationSeconds) {
				percentage = 0;
			}
			
			let angle = (percentage * 360) / 100;
			const degrees = 360;
			
			if (angle < 0) {
				angle = 0;
			}
			if (angle > degrees) {
				angle = degrees;
			}
			
			let border = (angle <= 180)
				? 'linear-gradient(' + (90 + angle) + 'deg, transparent 50%, rgb(150, 150, 150) 50%), ' +
				'linear-gradient(90deg, rgb(150, 150, 150) 50%, transparent 50%)'
				
				: 'linear-gradient(' + (angle - 90) + 'deg, transparent 50%, white 50%), ' +
				'linear-gradient(90deg, rgb(150, 150, 150) 50%, transparent 50%)';
			
			return {
				'--border': border,
				'--textColor': this.textColor,
				'--iconsColor': this.iconsColor,
			};
		},
	},

	watch: {
		playing(value) {
		if (value) {
			this.audio.play();
		} else {
			this.audio.pause();
		}
		},
		file: {
		immediate: true,
		handler() {
			this.$nextTick(() => {
			this.$refs.player.load();
			});
		}
		}
	},
	
	mounted() {
		this.audio = this.$refs.player;

		if (this.audio) {
		this.audio.addEventListener('timeupdate', this.update);
		this.audio.addEventListener('loadeddata', this.load);
		this.audio.addEventListener('pause', this.onPause);
		this.audio.addEventListener('play', this.onPlay);
		this.$root.$on('pause-audio', this.onPause);
		}
	},
	beforeDestroy() {
		this.$root.$off('pause-audio', this.onPause);
	},
	
	methods: {
    
	isAppleDevice() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },

    async getAudioType() {
      try {
        if(this.isAppleDevice()){
          const response = await axios.get(this.file, {
            responseType: 'blob', // Asegúrate de que la respuesta sea un blob
          });
          const contentType = response.headers['content-type'];
          this.audioType = contentType;
          this.audioUrl = URL.createObjectURL(response.data);
        }
        this.defaultAudioData();
      } catch (error) {
        console.error('Error al obtener el tipo de audio:', error);
      }
    },
    defaultAudioData(){
      this.audioType = 'audio/mpeg';
      this.audioUrl = null;
    },
		// handlePlayClick() {
		// 	if (this.autoPlay) {
        //     	if (this.audio.paused) {
        //         	this.audio.play();
        //    		} else {
        //         	this.audio.pause();
        //     	}
        // }else{
		// 	this.playing = true;
		// 	this.$emit('audioPlayed');
		// }
		// },

		load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true;
				this.durationSeconds = parseInt(this.audio.duration);
				if (this.additionalInformation && this.additionalInformation.length > 0) {
					this.resources = this.additionalInformation.map(resource => {
						const startTime = parseInt(resource.startTime.split(':')[0]) * 60 + parseInt(resource.startTime.split(':')[1]);
						const endTime = parseInt(resource.endTime.split(':')[0]) * 60 + parseInt(resource.endTime.split(':')[1]);
						return {...resource, startTime, endTime};
					}).sort((a, b) => a.startTime - b.startTime);
				}
				if (this.museum.code!="MPICASSOM"){
					this.playing = this.autoPlay;
				}
				
			}
		},

		handlePlayClick() {
			if (!this.playing) {
				this.play();
			}else {
				this.pause();
			}
		},
		play() {
			console.log('play');
			this.audio.play().then(() => {
				this.playing = true;
				this.$emit('audioPlayed');
			}).catch(error => {
				console.error("Error playing audio", error);
				this.$emit('audoError', error);
			});
		},
		// pause() {
        // 	this.audio.pause();
        // 	this.playing = false;
        // 	this.$emit('audioPaused');
    	// },
		
    update() {
      this.currentSeconds = parseInt(this.audio.currentTime);
      if (this.resources.length > 0 && this.isNetworkOnline) {
        const resource = this.resources[0];
        if (!this.showAdditionalInformation && resource.startTime === this.currentSeconds) {
          this.showAdditionalInformation = true;
          this.showResourceInformation(resource);
        } else if (this.showAdditionalInformation && resource.endTime === this.currentSeconds) {
          if (resource.resourceType === 'TEXT' || resource.resourceType === 'IMAGE') {
            this.$swal.close();
          }
        }
      }
    },

    showResourceInformation(resource) {
      this.$swal({
        title: `<span>${resource.title}</span>`,
        html: `${this.getResource(resource)}`,
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        padding: '15px 10px 10px 10px',
        imageUrl: resource.resourceType === 'IMAGE' ? resource.url : '',
        imageWidth: '100%',
        imageHeight: '100%',
        onBeforeOpen: () => {
          if (resource.resourceType !== 'TEXT' && resource.resourceType !== 'IMAGE') {
            this.audio.pause();
          }
        },
        onDestroy: () => {
          if (resource.resourceType !== 'TEXT' && resource.resourceType !== 'IMAGE') {
            this.audio.play();
          }
          this.resources.shift();
          this.showAdditionalInformation = false;
        }
      });
    },

	onPause() {
        this.playing = false;
        if (this.audio) {
            this.audio.pause();
        }
        this.$emit('audioPaused');
        if (this.currentSeconds === this.durationSeconds) {
            this.currentSeconds = 0;
            this.$emit('audioFinished');
        }
    },

    onPlay() {
      this.showControls = true;
      this.playing = true;
      this.$emit('audioPlayed');
    },

	togglePlay() {
      this.playing = !this.playing;
    },
		
		backwardAudio() {
			this.audio.currentTime -= 2;
			this.$emit('audioBackward');
		},
		
		forwardAudio() {
			this.audio.currentTime += 2;
			this.$emit('audioForward');
		},
		getResource(resource) {
			if (resource.resourceType === 'AUDIO') {
				
				return `<audio v-if="file" :id="'player-' + ${this.file}" ref="player" class="player" controls>
							<source :src="${this.file}" type="audio/mpeg">
						</audio>`;
			} else if (resource.resourceType === 'VIDEO') {
				return `<video controls style="width: 100%;">
									<source src="${resource.localizations[0].url}" type="video/mp4">
								</video>`;
			} else if (resource.resourceType === 'TEXT') {
				return `<div style="height:100%; overflow-y: scroll;"><span style="font-size: 13px;">${resource.localizations[0].content}</span></div>`;
			}
			return '';
		},
		setBalance(data) {
			this.volumeNodeL.gain.value = 1 - (data.target.value/this.maxminBalance);
			this.volumeNodeR.gain.value = 1 + (data.target.value/this.maxminBalance);
		},
		defaultBalance() {
			const balanceHtml = document.getElementById("balancer-html");
			balanceHtml.value = 0;
			this.volumeNodeL.gain.value = 1 - (0/this.maxminBalance);
			this.volumeNodeR.gain.value = 1 + (0/this.maxminBalance);
		},
		initBalance(option = 'left') {
			const balanceHtml = document.getElementById("balancer-html");
			if(option === 'left') {
				balanceHtml.value = -this.maxminBalance;
				this.volumeNodeL.gain.value = 1;
				this.volumeNodeR.gain.value = 0;
			} else {
				balanceHtml.value = this.maxminBalance;
				this.volumeNodeL.gain.value = 0;
				this.volumeNodeR.gain.value = 1;
			}

		},
		reduceVolumen(volumen = 0.2) {
			this.audio.volume = volumen;
		},
	},
};
</script>

<style lang="scss" scoped>
.background{
	background-color: #E85F31 !important;
}
.player {
	audio {
		display: none;
	}
	
	.player-controls {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		
		.player-buttons {
			width: 55%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
			height: 65px;
			
			/* See details about the play button percentage coloring here https://stackoverflow.com/a/21198231 */
			.active-border {
				position: relative;
				text-align: center;
				width: 65px;
				min-width: 65px;
				height: 65px;
				min-height: 65px;
				border-radius: 100%;
				
				background-color: var(--iconsColor);
				background-image: var(--border);
				
				animation: linear 1s;
			}
			
			.player-button {
				background-color: transparent;
				border: none;
				z-index: 1;
				
				&:focus {
					outline: none;
				}
				
				&.backward,
				&.forward {
					.mm-icon {
						color: var(--iconsColor);
					}
				}
				
				&.backward {
					margin-right: 20px;
				}
				
				&.forward {
					margin-left: 20px;
				}
				
				&.play {
					position: relative;
					top: 4px;
					left: 0;
					text-align: center;
					width: 57px;
					height: 57px;
					border-radius: 100%;
					background-color: rgb(73, 73, 73);
					
					.mm-icon.play, .mm-icon.pause {
						color: var(--iconsColor);
						position: absolute;
						left: 11px;
						top: 12px;
					}
				}
			}
		}
		
		.player-timer {
			font-size: 12px;
			color: var(--textColor);
			z-index: 1;
		}
	}
}
.slidecontainer {
	width: 50%;
	padding-top: 10px;
	display: none;
  }

.titleSlide {
	display: flex;
	justify-content: center;
}
  
.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	background: #2e2e2e;
	outline: none;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius: 15px;
  }
  
  
.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 15px;
	height: 15px;
	border-radius: 100%;
	background: rgb(255, 255, 255);
	cursor: pointer;
}
  
.slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: white;
	cursor: pointer;
}
</style>
